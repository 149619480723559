/* General Table Styles */
.dynamic-table-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

h2 {
  margin-top: 20px;
  font-size: 24px;
}

/* Table Button Styling */
.add-button {
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.print-button-container {
  margin-top: 20px;
  text-align: center;
}

.print-button {
  background-color: #ffc107;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.table-actions {
  display: flex;
  justify-content: space-between;
}

.column-input,
.row-input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.editable-table th {
  text-align: left;
  background-color: #f8f9fa;
  padding: 10px;
}

.editable-table td {
  text-align: left;
  padding: 10px;
}

/* Printable Table Styles */
.printable-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.printable-table th,
.printable-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.printable-table th {
  background-color: #f8f9fa;
  position: relative;
}

/* Column Resizing */
.resizable-column {
  position: relative;
}

.resize-handle {
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
}

/* Add Row and Column Button Positioning */
.add-button {
  margin-right: 20px;
}
