.container {

    justify-content: center;
    align-items: center;
    /* Set the height to the desired value */
    font-family: 'Kalimati Regular', sans-serif;
}

.title {
    text-align: center;
}