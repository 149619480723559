.email-sender-container {
    margin: 20px auto;
    max-width: 600px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.email-sender-container h1 {
    font-size: 32px;
    margin-top: 0;
    text-align: center;
}

.email-sender-content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.available-emails,
.selected-emails {
    flex: 1;
}

.available-emails h2,
.selected-emails h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.available-emails ul,
.selected-emails ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.available-emails li,
.selected-emails li {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    transition: background-color 0.2s ease-in-out;
}

.available-emails li:hover,
.selected-emails li:hover {
    background-color: #ebe
}

button {
    background: #1a202c;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

button:hover {
    background: #2d3748;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    transform: translate(-2px, -2px);
}

.send-email-text {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}