.App {
  text-align: center;
  align-items: center;
}

.GridContainer {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
}

.left-section,
.right-section {
  /* max-height: 100vh; */
  overflow: auto;
}

.right-section {
  position: sticky;
  top: 0;
}

::-webkit-scrollbar {
  width: 0.2em;
  background-color: #f5f5f5;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loadingCenter {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  /* -ms-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.englishFont {
  font-family: "Times New Roman", Times, serif;
  /* color: purple; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.asideClass {
  float: left;
  width: 12%;
  /* height: 500px; */
  /* background-color: rgb(126, 243, 103); */
  margin: 0.5%;
  padding: 0.1%;
}

.middlepage {
  margin-left: 13%;
}

.DateRow {
  /* border: 1px solid black; */
  color: red;
  width: 150px;
}

.secretariat {
  /* float: right; */
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  width: 80%;
}

.nagarikta,
.businessClose {
  font-family: "Kalimati", "Regular";

  /* margin-top: 30px;
  margin-bottom: 20px; */
  text-align: center;
  /* border: solid; */
  background-color: white;
  position: relative;

  /* padding: 5%; */
}

.englishDiv {
  font-family: "Times New Roman", Times, serif;

  /* margin-top: 30px;
  margin-bottom: 20px; */
  text-align: center;
  /* border: solid; */
  background-color: white;
  position: relative;

  /* padding: 5%; */
}

.officeInformation {
  visibility: hidden;
  display: none;
  position: relative;
}

.signature {
  text-align: left;
  float: right;
}

.container-fluid {
  /* font-family: 'Kalimati'; */
  font-family: "Kalimati", "Regular";
  /* margin-left: 12.5%;
  margin-top: 10px; */
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  /* overflow: hidden; */
}

.heading h1 {
  font-size: 4rem;
  text-align: center;
  letter-spacing: calc(100vw - 95%) !important;
  margin: 0;
  padding: 0;
}

.mukesh {
  color: rgb(1, 1, 20);
  text-align: justify;
  /* background-color: #61dafb; */
  border-color: white;
  font-family: "Kalimati", "Regular";
}

.inputbox {
  color: purple;
  width: fit-content;
}

.asideRight {
  float: right;
  width: 50%;
  margin: 1%;
  padding: 0.5%;

  color: firebrick;
  /* background-color: #61dafb; */
}

.selectContainer {
  /* border: 2px dashed black; */
  text-align: center;
  padding: 2px;
  margin: 2px;
  /* width: fit-content; */
  /* padding: 5%; */
  /* margin: 10% */

  background: linear-gradient(to right, #9403f5, #0195f8);
}

option {
  width: fit-content;
  color: rgb(243, 6, 6);
  background: linear-gradient(to right, #f3f0f0, #6bafdd);
  padding: 2px;
}

.solidBorder {
  border: solid;
}

.col {
  margin: 0%;
  background-color: rgb(206, 241, 205);
  background: linear-gradient(to right, #f3f0f0, #cdf7cc);
  /* border: solid; */
  align-items: center;
  border-width: 1px;
}

.row {
  background-color: rgb(177, 187, 243);
  background: linear-gradient(to right, #f3f0f0, #ccdff7);
}

.block {
  width: 90%;
}

.HeadingInfo {
  padding: 0.1%;
}

.leftSide {
  position: relative;
}

.photo {
  position: absolute;
  top: 1.9%;
  right: 5%;
}

.pratilipiphoto {
  top: 0;
  right: 5%;
}

input {
  text-transform: uppercase;
  font-language-override: normal;
}

p {
  text-align: left;
}

.left {
  text-align: left;
  align-items: left;
  align-content: left;
}

.rect {
  text-align: center;
  border: solid;
  /* border-color: red; */
  border-width: 1px;
  line-height: 140px;
  width: 121px;
  height: 140px;
  background-color: azure;
  /* padding: 20px; */
  /* padding: 20px; */
  margin: 2px;
}

.content {
  margin-left: 20px;
  margin-right: 10px;
}

.email-sender-container {
  margin: 20px auto;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tableRow {
  width: 100%;
  /* background-color: red; */
  color: #9403f5;
}

/* li:nth-child(odd) {
  background-color: #e2f891e7;
  
} */

@media print {
  body {
    /* width: 21cm;
    height: 29.7cm; */
    margin: 10mm;
    margin-left: 1in;
    margin-right: 0.5in;
    background-color: white;
    background: white;
    /* margin: 10mm 30mm 20mm 20mm; */
    /* padding: 10mm 10mm 10mm 10mm; */
    /* change the margins as you want them to be. */
  }

  .table-print {
    background-color: red;
    background: black;
  }

  .onePage {
    page-break-after: always;
    width: 21cm;
    height: 29.7cm;
    padding-right: 10mm;
    /* margin: 10mm; */
    margin-top: 10mm;
    word-wrap: break-word;
    /* font-size: 1.25vh; */
  }

  .businessClose {
    /* page-break-after: always; */
    padding-right: 10mm;
    height: 29.7cm;
    width: 21cm;
    word-wrap: break-word;
  }

  .content {
    padding-right: 10mm;
  }

  .officeInformation {
    /* height: 6cm; */
    color: red;
    visibility: visible;
    display: inline;
    align-items: center;
  }

  .officeFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: red;
    text-align: center;
  }

  .row,
  .col {
    background: white;
  }

  .App,
  .GridContainer,
  .left-section,
  .right-section,
  .center,
  .loadingCenter,
  .App-logo,
  .App-header,
  .englishFont,
  .App-link,
  .asideClass,
  .middlepage,
  .DateRow,
  .secretariat,
  .nagarikta,
  .businessClose,
  .officeInformation,
  .signature,
  .container-fluid,
  .heading,
  .mukesh,
  .inputbox,
  .asideRight,
  .selectContainer,
  option,
  .solidBorder,
  .col,
  .row,
  .block,
  .leftSide,
  .photo,
  .pratilipiphoto,
  input,
  p,
  .left,
  .rect,
  .content,
  .email-sender-container,
  .tableRow,
  li {
    color: black !important;
  }
}
.HeadingInfo{
  color: red;
}

.logo {
  height: 3cm;
  width: 3.5cm;
  background-color: red;
}

.icon {
  height: 0.75cm;
  width: 0.75cm;
}

.printAction {
  position: fixed;
  z-index: 100;
  bottom: 3%;
  right: 3%;
}

.nepalLogo {
  position: fixed;

  size-adjust: auto;
  top: 3%;
  left: 5%;

  /* scale: 0.5; */
  /* height: auto; */
}

.englishDate {
  position: fixed;
  /* top: -20px; */
  right: 5%;
  margin-top: -20px;
  /* padding-bottom: 20px; */
}

img {
  width: 100%;
  height: 100%;
}

.municipalityLogo {
  position: fixed;
  scale: auto;
  top: 3%;
  right: 5%;
}

.sticky {
  position: sticky;
  top: 0;
  /* background-color: green; */
  border: 2px solid #dde9dd;
}

table {
  width: 100%;
  border: 1px solid black;
  text-align: center;
  /* word-wrap: break-all; */
  word-break: break-word;
  /* table-layout: fixed; */
}

td {
  border: 1px solid black;
  align-items: center;
  align-content: center;
  /* display: block; */
  /* margin: 3px;
  padding: 3px; */

  /* word-break: break-all; */
  /* white-space: pre; */
}

.nataBoxes {
  display: inline-block;
  /* margin-bottom: 10px; */
  color: black;
}

.nataName {
  border-bottom: 1px solid black;
  /* background-color: aqua; */
}

tr {
  border: 1px solid black;
  align-items: center;
  align-content: center;
}

table {
  align-items: center;
}

.table-bill {
  width: 100%;
  border-collapse: collapse;
}

.table-bill th,
.table-bill td {
  border: 1px solid black;
  padding: 1px;
  text-align: center;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-overflow: ellipsis;
  /* Show ellipsis for overflow text */
}

th {
  border: 2px solid black;
}

body,
textarea,
.asideClass,
.backgroundClass {
  background: #bdc3c7;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f3f0f0, #6bafdd);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

input {
  background: linear-gradient(to top, #f3f0f0, #a2cfec);
}

/* .col {
  background: linear-gradient(to left, #f3f0f0, #d84343);
} */

.form-label {
  width: 30%;
}

textarea {
  height: fit-content;
  overflow: hidden;
}

.label {
  display: inline-block;
  width: 200px;
}

.label_700 {
  display: inline;
  width: 330px;
}

@media screen and (max-width: 480px) {
  /* Center all text */
  body {
    text-align: center;
  }

  /* Remove margins and padding from some elements */
  h1,
  h2,
  h3,
  p {
    margin: 0;
    padding: 0;
  }

  /* Make images responsive */
  img {
    max-width: 100%;
    height: auto;
  }

  /* Reduce font size of headings */
  h1,
  h2,
  h3 {
    font-size: 24px;
  }

  /* Add some spacing between elements */
  section {
    margin-bottom: 16px;
  }
}
