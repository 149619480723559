.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    margin: 20px 0;
}

.custom-table th,
.custom-table td {
    padding: 10px;
    vertical-align: top;
    border: none;
    /* Remove the border */
}

.custom-table th {
    font-weight: normal;
    /* Remove bold styling */
    font-size: 1em;
    /* Adjust the font size if necessary */
    text-decoration: underline;
    /* Underline the titles */
}

.custom-table td div {
    margin: 5px 0;
}

/* Ensure no borders are present around the table */
.custom-table,
.custom-table tr,
.custom-table th,
.custom-table td {
    border: none;
    /* Remove any border styling */
}

/* TableTippani.css */
.borderless-table {
    border: none;
}

.borderless-table th,
.borderless-table td,
.borderless-table tr {
    border: none;
}